@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=auto')

$body: #fff
$theme: #D3B1DF
$dark: #272C36
$white: #fff
$light: #C6C6C6
$medium: #683CC2
$lite: #fafafa
$blite: #efefef

$bg1: #F1FFD3
$bg2: #F2F6FF
$bg3: #FFF7CA

$button: $theme

$blue: #1677FF
$violet: #FA286D
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660

$metal: #484D58

$basic: 'Inter Tight', sans-serif
$intro: 'Inter Tight', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
