// Font Family

*
  font-family: $basic

// Font Sizes

@for $i from 50 through 150
  .fz-#{$i}
    font-size: #{$i * .01}em !important

// Font Weight

$wts: 100, 200, 300, 400, 500, 600, 700, 800, 900

@each $wt in $wts
  .fw-#{$wt}
    font-weight: #{$wt} !important

// Line Height

$values: 100, 105, 110, 115, 120, 125, 130, 135, 140, 150, 175, 200, 225, 250

@each $value in $values
  .lh-#{$value}
    line-height: #{$value * .01} !important

// Padding PX

@for $i from 0 through 64
  .p
    &-t-#{$i}
      padding-top: #{$i}px !important
    &-b-#{$i}
      padding-bottom: #{$i}px !important
    &-l-#{$i}
      padding-left: #{$i}px !important
    &-r-#{$i}
      padding-right: #{$i}px !important
    &-x-#{$i}
      padding-left: #{$i}px !important
      padding-right: #{$i}px !important
    &-y-#{$i}
      padding-top: #{$i}px !important
      padding-bottom: #{$i}px !important

// Margin PX
@for $i from 0 through 200
  .m
    &-t-#{$i}
      margin-top: #{$i}px !important
    &-b-#{$i}
      margin-bottom: #{$i}px !important
    &-l-#{$i}
      margin-left: #{$i}px !important
    &-r-#{$i}
      margin-right: #{$i}px !important
    &-x-#{$i}
      margin-left: #{$i}px !important
      margin-right: #{$i}px !important
    &-y-#{$i}
      margin-top: #{$i}px !important
      margin-bottom: #{$i}px !important

@for $i from 0 through 1200

  // Height in PX

  .hp-#{$i}
    height: #{$i}px !important
    min-height: #{$i}px !important
    max-height: #{$i}px !important

  // Width in PX

  .wp-#{$i}
    width: #{$i}px !important
    min-width: #{$i}px !important
    max-width: #{$i}px !important

// Border

.bt
  border: none !important
  border-top: 1px solid $lite !important
.bb
  border: none !important
  border-bottom: 1px solid $lite !important
.bl
  border: none !important
  border-left: 1px solid $lite !important
.br
  border: none !important
  border-right: 1px solid $lite !important

// Border Width

@for $i from 1 through 20
  .bw-#{$i}
    border-width: #{$i}px !important

@for $i from 0 through 120

  // Height in VH

  .hv-#{$i}
    height: #{$i}vh !important
    +v-sm
      height: auto !important
      padding-top: 2% !important
      padding-bottom: 2% !important

  // Corner Radius

  .r-#{$i}
    border-radius: #{$i}px !important
    &-tl
      border-top-left-radius: #{$i}px !important
    &-tr
      border-top-right-radius: #{$i}px !important
    &-bl
      border-bottom-right-radius: #{$i}px !important
    &-br
      border-bottom-right-radius: #{$i}px !important

  // Empty Spaces

  .s-#{$i}
    height: #{$i}px !important
    width: 100% !important
    overflow: hidden !important
    position: relative

  // Width in %

  .w-#{$i}
    width: #{$i*1%} !important
    min-width: #{$i*1%} !important
    max-width: #{$i*1%} !important
    &.wide
      +v-sm
        width: 96% !important
        min-width: 96% !important
        max-width: 96% !important
        margin-left: 2% !important
        margin-right: 2% !important

// Mobile Empty Spaces

@for $i from 24 through 120
  .s-#{$i}
    +v-sm
      height: 24px !important

// Shadow

.shadow-xs
  box-shadow: rgba(0, 0, 0, 0.025) 0px 1px 2px 0px

// Margin Auto

.ma
  margin-left: auto !important
  margin-right: auto !important
.mla
  margin-left: auto !important
.mra
  margin-right: auto !important

// Image Fit

.obfc
  object-fit: cover !important

.obfsd
  object-fit: scale-down !important

// Flex Grid

.flex
  display: flex !important

.ffrw
  flex-flow: row wrap !important

.ffrnw
  flex-flow: row nowrap !important

.fdr
  flex-direction: row !important

.fdc
  flex-direction: column !important

.aic
  align-items: center !important

.aifs
  align-items: flex-start !important

.aife
  align-items: flex-end !important

.jcc
  justify-content: center !important

.jcfs
  justify-content: flex-start !important

.jcfe
  justify-content: flex-end !important

.jcsa
  justify-content: space-around !important

.jcsb
  justify-content: space-between !important

// Text Align

.tl
  text-align: left !important
  &.tmc
    +v-sm
      text-align: center !important
.tr
  text-align: right !important
  &.tmc
    +v-sm
      text-align: center !important

.tc
  text-align: center !important

// Text Transform

.upp
  text-transform: uppercase !important

.nupp
  text-transform: none !important

// Overflow

.oh
  overflow: hidden !important

.ohx
  overflow-x: hidden !important

.ohy
  overflow-y: hidden !important

// Mobile

.m-hide
  +v-md
    display: none !important
.table-mob
  td,
  th
    padding: 0 40px 10px
    +v-sm
      padding: 0 5%
    +v-xs
      zoom: .75
      padding: 0 5%
      transform: translateY(-40px)
