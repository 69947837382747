// Colors
.c
  &-body
    color: $body !important
  &-metal
    color: $metal !important
  &-white
    color: $white !important
  &-dark
    color: $dark !important
  &-blue
    color: $blue !important
  &-pink
    color: $pink !important
  &-violet
    color: $violet !important
  &-marine
    color: $marine !important
  &-light
    color: $light !important
  &-lite
    color: $lite !important
  &-theme
    color: $theme !important
  &-green
    color: $green !important
  &-medium
    color: $medium !important
