.b-none
  border: none !important

// Background Colors
.bg
  &-metal
    background-color: $metal !important
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-blue
    background-color: $blue !important
  &-marine
    background-color: $marine !important
  &-light
    background-color: $light !important
  &-lite
    background-color: $lite !important
  &-theme
    background-color: $theme !important
  &-grey
    background-color: $grey !important

#top
  position: relative
  width: 100%
  height: 100vh
  top: 0
  left: 0
  overflow: hidden
  z-index: 0
  background: linear-gradient(45deg, #e73c7e, #23a6d5, #23d5ab, #ee7752)
  background-size: 400% 400%
  animation: gradient 5s ease infinite
  height: 100vh
  +v-sm
    top: -50px
    height: auto !important
    padding: 150px 0
  .bg-intro
    position: absolute
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    z-index: 1
    +v-sm
      display: none
    +v-md
      img
        object-fit: contain !important
    +v-xl
      img
        object-fit: contain !important

.gallery
  background-color: rgba(104,66,205,.5)
  border: 1px solid rgba(104,66,205,.25)
  &.project
    background-color: transparent
    border: 2px solid $blite
  .title-sub
    +v-sm
      text-align: left !important
      font-size: 14px !important

.avatars
  img
    margin-left: -8px
    border: 2px solid rgba(255,255,255,.25)
    border-radius: 16px
    &:first-of-type
      margin-left: 0

.slick-dots
  li
    button
      &:before
        color: #eee
    &.slick-active
      button
        &:before
          color: #fff

.slick-arrow
  opacity: .5

@keyframes gradient
  0%
    background-position: 0% 50%

  50%
    background-position: 100% 50%

  100%
    background-position: 0% 50%

#cta
  position: relative
  width: 100%
  height: 100vh
  top: 0
  left: 0
  overflow: hidden
  z-index: 0
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)
  background-size: 400% 400%
  animation: gradient 5s ease infinite
  height: 100vh
  +v-sm
    height: auto !important
    padding: 30vh 0
    margin-bottom: -10vh

#pr
  position: relative
  width: 100%
  height: 100vh
  top: 0
  left: 0
  overflow: hidden
  z-index: 0
  background: linear-gradient(-30deg, #23d5ab, #ee7752, #23a6d5, #e73c7e)
  background-size: 400% 400%
  animation: gradient 5s ease infinite
  height: 100vh
  +v-sm
    height: auto !important
    margin-top: 30vh !important
    padding: 40px 0 120px

#how
  position: relative
  width: 100%
  height: 100vh
  top: 0
  left: 0
  overflow: hidden
  z-index: 0
  background: linear-gradient(30deg, #23a6d5, #e73c7e, #23d5ab, #ee7752)
  background-size: 400% 400%
  animation: gradient 5s ease infinite
  height: 100vh
  +v-sm
    height: auto !important
    min-height: 80vh !important
    padding: 40px 0
