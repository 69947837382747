.button
  display: inline-flex
  justify-content: center
  align-items: center
  width: auto
  min-width: 160px
  height: 48px
  padding: 0 20px
  color: $white !important
  font-size: .92em
  font-weight: 400
  border-radius: 8px
  transition: all ease-out .25s
  background-color: $violet
  border: 2px solid $violet
  outline: none !important
  &.mini
    min-width: 105px
    height: 35px
    padding: 0 16px
    border: none
    background-color: $medium
    &:hover
      background-color: $blue
      border: none
    &.project
      background-color: $blue
      &:hover
        background-color: $medium
    &.first
      margin-right: 16px
      +v-sm
        margin-right: auto
  &.up
    height: 40px
    margin-left: 40px
    +v-sm
      margin-left: auto
  &.first
    margin-right: 24px
    +v-sm
      margin-right: auto
  &.outline
    color: $white !important
    background-color: transparent
    &.cookie
      color: $violet !important
      &:hover
        color: $white !important
        background-color: $blue
        border: 2px solid $blue
  &:hover
    background-color: $blue
    border: 2px solid $blue
